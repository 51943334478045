import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { slugify } from "../util/utilityFunctions"
import Fade from "react-reveal/Fade"
// Components
import Image from "../components/image"
import InnerWrapper from "../components/innerWrapper"
// Styles
import styles from "./scss/latest-posts.module.scss"

const latestPosts = () => (
  <StaticQuery
    query={graphql`
      {
        allContentfulBlogPost(
          sort: { fields: publishDate, order: ASC }
          limit: 3
        ) {
          edges {
            node {
              id
              image {
                title
                description
                fixed(width: 300, quality: 100) {
                  src
                  srcSet
                  srcSetWebp
                }
              }
              title
              publishDate(formatString: "MMMM D, YYYY")
              category
            }
          }
        }
        contentfulBlogSection {
          title
        }
      }
    `}
    render={data => (
      <section className={`section ${styles.latestPosts}`}>
        <InnerWrapper width={1024}>
          <div className="banner">
            <h2 className="ribbon">
              <span className="top">&nbsp;</span>
              <span className="text">From the Blog</span>
              <span className="bottom">&nbsp;</span>
            </h2>
          </div>
          <div className={styles.posts}>
            <Fade bottom>
              {data.allContentfulBlogPost.edges.map(post => (
                <Link
                  to={`/blog/${slugify(post.node.title)}`}
                  className={styles.post}
                  key={post.node.id}
                >
                  <Image image={post.node.image} />
                  <h4>{post.node.title}</h4>
                  <span>- {post.node.category} -</span>
                </Link>
              ))}
            </Fade>
          </div>
          <Link to="/blog" className={styles.readMore}>
            View All
          </Link>
        </InnerWrapper>
      </section>
    )}
  />
)

export default latestPosts
