import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { StaticQuery, graphql, Link } from "gatsby"
import Slide from "react-reveal/Slide"
// Components
import InnerWrapper from "../components/innerWrapper"

// Styles
import styles from "./scss/products.module.scss"
const products = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulProductsSection {
          title
          body {
            json
          }
          fruitImage {
            fixed(width: 250, quality: 50) {
              src
              srcSet
              srcSetWebp
            }
          }
          vegetableImage {
            fixed(width: 250, quality: 50) {
              src
              srcSet
              srcSetWebp
            }
          }
          dairyImage {
            fixed(width: 250, quality: 50) {
              src
              srcSet
              srcSetWebp
            }
          }
          frozenImage {
            fixed(width: 250, quality: 50) {
              src
              srcSet
              srcSetWebp
            }
          }
        }
      }
    `}
    render={data => (
      <section className="section products" style={{ background: `#f9f9f9` }}>
        <InnerWrapper width={1024}>
          <div className="banner">
            <h2 className="ribbon">
              <span className="top">&nbsp;</span>
              <span className="text">
                {data.contentfulProductsSection.title}
              </span>
              <span className="bottom">&nbsp;</span>
            </h2>
          </div>

          <div className={styles.productsContainer}>
            <div className={styles.product}>
              {documentToReactComponents(
                data.contentfulProductsSection.body.json
              )}
              <Link
                to="/products"
                state={{ category: "" }}
                className={styles.viewAll}
              >
                View All
              </Link>
            </div>
            <div className={styles.empty}></div>
            <Slide right>
              <div
                className={styles.product}
                style={{
                  backgroundImage: `url(${data.contentfulProductsSection.fruitImage.fixed.src})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <Link
                  to="/products"
                  state={{ category: "Fruit" }}
                  className={styles.image}
                >
                  <h3>Fruit</h3>
                </Link>
              </div>
              <div
                className={styles.product}
                style={{
                  backgroundImage: `url(${data.contentfulProductsSection.vegetableImage.fixed.src})`,

                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <Link
                  to="/products"
                  state={{ category: "Vegetables" }}
                  className={styles.image}
                >
                  <h3>Vegetables</h3>
                </Link>
              </div>
              <div
                className={styles.product}
                style={{
                  backgroundImage: `url(${data.contentfulProductsSection.dairyImage.fixed.src})`,

                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <Link
                  to="/products"
                  state={{ category: "Dairy" }}
                  className={styles.image}
                >
                  <h3>Dairy</h3>
                </Link>
              </div>
              <div
                className={styles.product}
                style={{
                  backgroundImage: `url(${data.contentfulProductsSection.frozenImage.fixed.src})`,

                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <Link
                  to="/products"
                  state={{ category: "Frozen" }}
                  className={styles.image}
                >
                  <h3>Frozen</h3>
                </Link>
              </div>
            </Slide>
          </div>
        </InnerWrapper>
      </section>
    )}
  />
)

export default products
