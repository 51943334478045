import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { FaAngleDoubleDown } from "react-icons/fa"
import Fade from "react-reveal/Fade"

// Components
import SEO from "../SEO"
import GA from "../components/ga"
import Layout from "../components/layout"
import About from "../components/about"
import Services from "../components/services"
import Products from "../components/products"
import OrderNow from "../components/ordernow"
import LatestPosts from "../components/latest-posts"
import NewsLetter from "../components/newsLetter"
// import Instagram from "../components/instagram"
import Sidebar from "../components/sidebar"

// Styles.
import styles from "./scss/home.module.scss"
import "./scss/index.scss"

class Index extends Component {
  state = {
    mobile: false,
  }
  componentDidMount() {
    if (/Mobi/.test(navigator.userAgent)) {
      this.setState({ mobile: true })
    }
  }

  render() {
    const { data } = this.props
    return (
      <>
        <SEO title="Brophy Brothers | Home" />
        <GA />
        <Layout>
          <div className={styles.hero}>
            {/* {this.state.mobile ? (
              <img
                className={styles.img}
                src={data.contentfulHomePageHero.gif.file.url}
                alt={data.contentfulHomePageHero.gif.description}
              />
            ) : ( */}
            <video autoPlay="autoplay" playsInline muted loop>
              <source
                src={data.contentfulHomePageHero.video.file.url}
                type={data.contentfulHomePageHero.video.file.contentType}
              />
            </video>
            {/* )} */}
            <div className={styles.content}>
              <Fade bottom duration={1500}>
                <h1>{data.contentfulHomePageHero.text}</h1>
              </Fade>
              <div className={styles.buttons}>
                <Link className={styles.readMore} to="/about">
                  About Us
                </Link>
                {/* <Link className={styles.viewProducts} to="/curbside">
                  Order Now
                </Link> */}
                <a
                  className={styles.viewProducts}
                  href="mailto:orders@brophybrothers.net"
                  target="_blank noopener noreferrer"
                >
                  Order Now
                </a>
              </div>
              <Fade bottom duration={1500} delay={1000}>
                <a
                  href="#about"
                  className={styles.arrow}
                  aria-label="Scroll down page"
                >
                  <FaAngleDoubleDown />
                </a>
              </Fade>
            </div>
          </div>
          <About />
          <OrderNow />
          <Services />
          <Products />
          <Sidebar />
          <LatestPosts />
          <NewsLetter />
          {/* <Instagram /> */}
          {/*  {/* <SidebarCart /> */}
        </Layout>
      </>
    )
  }
}

export default Index

export const query = graphql`
  query {
    contentfulHomePageHero {
      text
      video {
        description
        file {
          contentType
          url
        }
      }
      gif {
        description
        file {
          contentType
          url
        }
      }
    }
  }
`
