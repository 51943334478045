import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// Components
import InnerWrapper from "./innerWrapper"
// Styles
import styles from "./scss/about.module.scss"

const about = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulAboutSection {
          title
          shortDescription {
            json
          }
        }
      }
    `}
    render={data => {
      return (
        <section id="about" className={`section ${styles.about}`}>
          <InnerWrapper width={960}>
            <div className="banner">
              <h2 className="ribbon">
                <span className="top">&nbsp;</span>
                <span className="text">
                  {data.contentfulAboutSection.title}
                </span>
                <span className="bottom">&nbsp;</span>
              </h2>
            </div>
            {documentToReactComponents(
              data.contentfulAboutSection.shortDescription.json
            )}

            <Link to="/about" className={styles.readMore}>
              Read More
            </Link>
          </InnerWrapper>
        </section>
      )
    }}
  />
)

export default about
