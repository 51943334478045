import React from "react"
//import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, StaticQuery } from "gatsby"
import Slide from "react-reveal/Slide"
// Components
import Image from "../components/image"
import InnerWrapper from "../components/innerWrapper"
// Styles
import styles from "./scss/services.module.scss"

const services = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulServicesSection {
          title
          body {
            json
          }
        }
        allContentfulService {
          edges {
            node {
              id
              title
              image {
                title
                fixed(width: 128, quality: 100) {
                  src
                  srcSet
                  srcSetWebp
                }
              }
              body {
                body
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {/* <span id="services" aria-hidden="true">
          <br />
        </span> */}
        <section className={`section ${styles.services}`}>
          <InnerWrapper width={1024}>
            <div className="banner">
              <h2 className="ribbon">
                <span className="top">&nbsp;</span>
                <span className="text">
                  {data.contentfulServicesSection.title}
                </span>
                <span className="bottom">&nbsp;</span>
              </h2>
            </div>

            {/* {documentToReactComponents(
              data.contentfulServicesSection.body.json
            )} */}
            <div className={styles.serviceList}>
              {data.allContentfulService.edges.map((service, index) => {
                const left = index % 2 === 0 ? true : false
                const right = index % 2 === 0 ? false : true

                return (
                  <Slide
                    left={left}
                    right={right}
                    duration={1300}
                    key={service.node.id}
                  >
                    <div className={styles.service}>
                      <h3>{service.node.title}</h3>
                      <Image image={service.node.image} />
                      <p>{service.node.body.body}</p>
                    </div>
                  </Slide>
                )
              })}
            </div>
          </InnerWrapper>
        </section>
      </>
    )}
  />
)

export default services
